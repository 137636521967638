import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
export const activityKeys = ['textRevealOneHow', 'textRevealIncludeHow', 'textRevealActivitiesHow'];
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const TextReveal = makeShortcode("TextReveal");
const SingleImage = makeShortcode("SingleImage");
const layoutProps = {
  activityKeys,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "ways-to-connect-better"
    }}>{`Ways to connect better`}</h1>
    <p>{`If you answered 'No' to some of the questions, perhaps you could look into ways that you could become more involved in your teenager's life. `}<strong parentName="p">{`Here are some suggestions`}</strong>{`:`}</p>
    <h3 {...{
      "id": "regularly-spend-one-on-one-time-with-your-teenager"
    }}>{`Regularly spend one-on-one time with your teenager.`}</h3>
    <p>{`"Regularly" doesn't have to be every day, and you shouldn't force one-on-one time on your teenager.`}</p>
    <TextReveal id="textRevealOneHow" header={<b>Want some tips on how to do this?</b>} variant="h6" mdxType="TextReveal">
  <p>
    You should discuss with your teen how often you spend time together and what you do during this time. Make a habit
    of doing something special with your teenager. It will also depend on how good your relationship currently is. You
    might want to start gradually and work towards regular one-on-one time.
  </p>
    </TextReveal>
    <h3 {...{
      "id": "try-including-your-teenagers-friends-in-family-activities"
    }}>{`Try including your teenager's friends in family activities.`}</h3>
    <TextReveal id="textRevealIncludeHow" header={<b>Why?</b>} variant="h6" mdxType="TextReveal">
  <p>This enables your teenager to have some independence whilst still being part of family occasions.</p>
    </TextReveal>
    <h3 {...{
      "id": "do-activities-as-a-family-on-a-regular-basis--together"
    }}>{`Do activities as a family on a regular basis – `}<em parentName="h3">{`together`}</em>{`!`}</h3>
    <TextReveal id="textRevealActivitiesHow" header={<b>Want some tips on how to do this?</b>} variant="h6" mdxType="TextReveal">
  <p>
    Give each family member an opportunity to suggest an activity that the whole family will enjoy. The aim is for
    “family time” to be pleasurable, not boring or seen as an obligation.
  </p>
    </TextReveal>
    <SingleImage smallGridSize={8} gridSize={8} src="/images/shared/Oriental_Mo_friends.svg" alt="Mother spending time with teenager and their friends" mdxType="SingleImage" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      